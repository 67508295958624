
@keyframes circle{
  0%{
    transform:rotate(0deg)
              translate(-65px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-65px)
              rotate(-360deg);
  }
}
.img-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100%;
}
.img-logo span {
  width: 1px;
  height: 40px;
  background: #ccc;
  margin: 0 7px 0 10px;
}

.img-logo::before {
  content: '';
  display: none;
  width:10px;
  height:10px;  
  background:#5982ff;
  border-radius:50%;
  position:absolute;
  top:0; bottom:0; left:0;right:0;
  overflow:hidden;
  margin:auto;
  animation: circle 0.3s linear infinite;
}