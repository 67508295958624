.wrapper {
  display: flex;
  background: #5982FF;
  height: 100vh;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

.container::before {
  content: '';
  background: url('../../assets/svg/img-data-right.svg');
  background-repeat: no-repeat;
  width: 107px;
  height: 605px;
  position: fixed;
  right: 0;
  top: 0;

}

.wrapper::before {
  content: '';
  background-image: url('../../assets/svg/img-data-left.svg');
  background-repeat: no-repeat;
  width: 402px;
  height: 388px;
  position: fixed;
  left: 0;
  bottom: 0;


}

.form {
  position: relative;
  background: #FFF;
  border-radius: 5px;
  z-index: 0;
  border: 1px solid#5982FF;
}